<template>
    <div class="footer-section mb-0">
        <v-container class="pt-10">
            <v-row justify="center">
                <v-col cols="12">
                    <h2 class="heading-text">We<span style="color: #faa41a">Coin</span>
                    </h2>
                </v-col>
                <v-col cols="12"
                       md="3">
                    <h4>About</h4>
                    <p>
                        WeCoin is a unique payment gateway, enjoy hassle free financing with your personal and
                        business
                        needs.
                    </p>
                    <div class="buttons">
                        <v-btn href="https://wecoinnetwork.com/dashboard/#/login"
                               class="ma-2 footer-button"
                               outlined
                               rounded>
                            Login
                        </v-btn>
                        <v-btn href="https://wecoinnetwork.com/dashboard/#/register"
                               class="ma-2 footer-button"
                               outlined
                               rounded>
                            Signup
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12"
                       md="3">
                    <h4>Developers</h4>
                    <!-- <p>
                    <ul>
                        <li>
                            WeCoin Money SCI
                        </li>
                        <li>
                            API / SCI Guide
                        </li>
                    </ul>
                    </p> -->
                    <v-list>
                        <v-list-item :to="'/paysafemoney'">
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-list-item-subtitle>
                                WeCoin Money SCI
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item :to="'/apiguide'">
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-list-item-subtitle>
                                API / SCI Guide
                            </v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12"
                       md="3">
                    <h4>Features</h4>

                    <!-- <p>
                    <ul>
                        <li>
                            Beginner's Guide
                        </li>
                        <li>
                            Exchangers
                        </li>
                        <li>
                            WeCoin Guard
                        </li>
                    </ul>
                    </p> -->
                    <v-list>
                        <v-list-item>
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-list-item-subtitle>
                                Beginner's Guide
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item :to="'/exchangers'">
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-list-item-subtitle>
                                Exchangers
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item :to="'/guard'">
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-list-item-subtitle>
                                WeCoin Guard
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item :to="'/aboutus'">
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-list-item-subtitle>
                                About Us
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item :to="'/contactus'">
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-list-item-subtitle>
                                Contact Us
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item :to="'/privacypolicy'">
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-list-item-subtitle>
                                Privacy Policy
                            </v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12"
                       md="3">
                    <h4>NEWSLETTER</h4>
                    <p>
                        Subscribe to receive latest updates.
                    </p>
                    <v-form class="multi-col-validation white-field"
                            lazy-validation
                            ref="form"
                            v-model="valid">
                        <v-text-field style="color: white !important;"
                                      class="white-field"
                                      label="Email Addresss"
                                      color="white"
                                      v-model="newsletter"
                                      :rules="genericRules"
                                      outlined
                                      dense>
                        </v-text-field>
                        <div class="buttons">
                            <v-btn @click="submit"
                                   class="ma-2 footer-button"
                                   outlined
                                   rounded>
                                Subscribe
                            </v-btn>
                        </div>
                    </v-form>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
                <v-col cols="12"
                       md="12"
                       lg="6">
                    <div class="reserved pt-5">
                        Copyright &copy; 2012-2023 PSM Inc. All rights reserved.
                    </div>
                </v-col>
                <v-spacer />
                <v-col cols="12"
                       md="12"
                       lg="6"
                       align="end">
                    <div class="reserved pt-5">
                        <a :href="item.link"
                           target="_blank"
                           v-for="(item, index) in icons"
                           :key="index">
                            <v-icon class="footer-icon">
                                {{ item.icon }}
                            </v-icon>
                        </a>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>

import apiServices from '@/services/api-services';

export default {
    data: () => ({
        component_heading: "CONTACT US",
        genericRules: [
            v => !!v || 'Field is required'
        ],
        valid: false,
        newsletter: '',
        heading: "Active Users",
        count: "98M+",
        // icons: [{ icon: 'mdi-twitter' }, { icon: 'mdi-send' }, { icon: 'mdi-instagram' }, { icon: 'mdi-linkedin' }, { icon: 'mdi-facebook' }]
        icons: [
            { link: 'https://twitter.com/wecoinnetwork', icon: 'mdi-twitter' },
            { link: 'https://www.instagram.com/wecoinnetwork/', icon: 'mdi-instagram' },
            { link: 'https://www.facebook.com/wecoinnetwork/', icon: 'mdi-facebook' }
        ]
    }),
    methods: {
        submit()
        {
            this.$refs.form.validate();
            if (this.$refs.form.validate())
            {
                apiServices.newsletter({ email: this.newsletter }).then(res =>
                {
                    if (res.statusCode == 200)
                    {
                        this.$swal({
                            title: `Newsletter`,
                            text: 'Subscribed successfully.',
                            confirmButtonColor: '#00b2a9',
                            icon: 'success',
                        })
                    }
                    else
                    {
                        this.$swal({
                            icon: 'error',
                            title: 'Newsletter',
                            confirmButtonColor: '#00b2a9',
                            text: 'Something went wrong!',
                        })
                    }
                });
            }
            else
            {
                this.$swal({
                    icon: 'error',
                    title: 'Newsletter',
                    confirmButtonColor: '#00b2a9',
                    text: 'Please fill out all fields.',
                })
            }
        },
    }
}
</script>
<style scoped>
h2.heading-text {
    font-size: 40px;
    font-weight: 800;
    color: white;
}

.footer-section {
    background: #355fac;
    background-image: url('../../assets/footer_bg.png');
}

h4 {
    color: white !important;
    margin: 10px 0px;
}

li {
    color: white !important;
}

p {
    color: white;
}

.theme--light.v-list {
    background: #355fac;
    color: white !important;
}

.v-list-item {
    min-height: 30px !important;
}

.theme--light.v-list-item:not(.v-list-item--disabled) .v-list-item__subtitle {
    color: white;
}

.footer-button {
    /* border-radius: 20px !important; */
    border: 2px solid #faa41a;
    color: #c9933f !important;
}

.footer-icon {
    margin: 0px 10px;
    color: white;
}

.reserved {
    color: white;
}

.theme--light.v-divider {
    border-color: rgba(248, 248, 248, 0.12) !important;
}

.mdi::before {
    color: white;
}

.v-text-field--outlined>>>fieldset {
    border-color: white;
}

.v-list .v-list-item--active {
    border-bottom: 0px solid #faa41a !important;
    color: white;
}

.v-list-item--link:before {
    background-color: transparent !important;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.white-field input {
    color: white !important
}
</style>
