<template>
    <v-app-bar app
               color="white"
               light
               elevation="4"
               elevate-on-scroll
               style="padding: 0px 40px;height: 90px;">
        <!-- <v-toolbar extended> -->
        <v-toolbar-title>
            <!-- <span style="color: #355fac">We</span>Coin -->
            <v-img :src="require(`@/assets/logo-we.png`)"
                   height="44px"
                   width="104px"
                   class="me-2"></v-img>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="d-lg-flex d-md-flex d-sm-none d-none">
            <v-list class="d-flex align-center nav-bar"
                    style="height:90px;">
                <v-list-item class="active-link"
                             link
                             v-for="(menu, index) in menus"
                             :key="index"
                             :to="menu.route">
                    <v-list-item-title class="nav-item">
                        {{ menu.title }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </div>
        <v-spacer></v-spacer>
        <div class="d-lg-flex d-md-flex d-sm-none d-none">
            <div class="buttons">
                <v-btn href="https://wecoinnetwork.com/dashboard/#/login" class="ma-2 login-button"
                       outlined>
                    Login
                </v-btn>
                <v-btn href="https://wecoinnetwork.com/dashboard/#/register" class="ma-2 get-started-button"
                       outlined>
                    Get Started
                </v-btn>
            </div>
        </div>
        <div class="d-lg-none d-md-none d-sm-flex d-flex">
            <div class="buttons">
                <v-menu bottom
                        origin="center center"
                        transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon
                               v-bind="attrs"
                               v-on="on">
                            <v-icon color="primary">mdi-format-align-justify</v-icon>
                        </v-btn>

                    </template>

                    <v-list class="align-center nav-bar"
                            style="background:white;height: 100vh;">
                        <v-list-item class="active-link"
                                     link
                                     v-for="(menu, index) in menus"
                                     :key="index"
                                     @click="onMenu(menu.route)">
                            <v-list-item-title class="nav-item">
                                {{ menu.title }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item class="buttons">
                            <v-btn class="ma-2 login-button"
                                   outlined href="https://wecoinnetwork.com/dashboard/#/login" target="_blank">
                                Login
                            </v-btn>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item class="buttons">
                            <v-btn href="https://wecoinnetwork.com/dashboard/#/register" class="ma-2 get-started-button"
                                   outlined>
                                Get Started
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <!-- </v-toolbar> -->
    </v-app-bar>
</template>

<script>
import router from '@/router';

export default {
    name: 'Header',
    data: () => ({
        menus: [
            { title: 'Home', route: '/home' },
            { title: 'About Team', route: '/about' },
            { title: 'Fees', route: '/fees' },
            { title: 'Exchangers', route: '/exchangers' },
            { title: 'Prices', route: '/prices' },
            { title: 'APIs', route: '/apis' },
            { title: 'FAQs', route: '/faqs' },
            { title: 'Blogs', route: '/blogs' },
        ]
    }),
    methods: {
        onMenu(route)
        {
            router.push(route)
        }
    }
};
</script>
<style>
.nav-item {
    color: #355fac !important;
    margin: 5px;
    font-size: 14px;
    font-weight: 400;
}

.nav-item:active {
    text-decoration: underline;
}

.nav-bar {
    margin: 0% 2%;
}

.buttons .login-button {
    border-radius: 20px !important;
    border: 2px solid #faa41a;
    /* border-color: #faa41a !important; */
    color: #faa41a !important;
    font-size: 12px;
    font-weight: 300;
}

.buttons .get-started-button {
    background-color: #faa41a !important;
    color: white !important;
    border-radius: 10px !important;
    font-size: 12px;
    font-weight: 300;
}

.v-toolbar__title {
    font-size: 32px;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #faa41a;
    font-weight: 800;
}

.v-menu__content{
    background: white;
}

.v-toolbar__content {
    height: 90px !important;
}

.v-list .v-list-item::before {
    color: white;
}

.v-list .v-list-item--active {
    border-bottom: 3px solid #faa41a;
    color: white;
}
</style>